import React, { useRef } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from './styles';

const TextAreaFieldSync = ({ field, value, isLocked, setNewValue }) => {
  const textboxRef = useRef(null);
  const { t } = useTranslation();

  const onChange = (args) => {
    setNewValue(args.value);
  };

  const onCreate = () => {
    textboxRef.current.respectiveElement.style.height = field.height + 'px';
  };

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>{t(field.label)}</FormInputLabelContainerStyled>
      <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
      <TextBoxComponent
        multiline={true}
        value={value === null ? '' : value}
        ref={textboxRef}
        change={onChange}
        created={onCreate}
        enabled={!isLocked}
        name={field.name}
        data-msg-containerid={`error-${field.attribute.key}`}
      />
      <div id={`error-${field.attribute.key}`} />
    </FormInputContainerStyled>
  );
};

TextAreaFieldSync.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any
};

export default TextAreaFieldSync;
