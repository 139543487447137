import { ToastOptions, toast as Toast } from 'react-toastify';
import React from 'react';

const toast = (() => {
  const options: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true
  };

  const format = (header, message?) => {
    const content = (
      <div>
        <div className='toast-text-bold'>{header}</div>
        {message != null ? <div>{message}</div> : null}
      </div>
    );
    return content;
  };

  return {
    default: (header, message?) => {
      Toast(format(header, message), options);
    },
    info: (header, message?) => {
      Toast.info(format(header, message), options);
    },
    success: (header, message?) => {
      Toast.success(format(header, message), options);
    },
    error: (header, message?) => {
      Toast.error(format(header, message), options);
    },
    warning: (header, message?) => {
      Toast.warn(format(header, message), options);
    }
  };
})();

const log = (() => {
  const style = {
    Parameter: {
      background: '#82E0AA',
      color: '#000'
    },
    Query: {
      background: '#87AADE',
      color: '#000'
    },
    Widget: {
      background: '#FF8800',
      color: '#000'
    },
    StoredProcedure: {
      background: '#F9E79F',
      color: '#000'
    },
    Function: {
      background: '#000080',
      color: '#fff'
    },
    Service: {
      background: '#000',
      color: '#fff'
    },
    Log: {
      background: '#A0A0A0',
      color: '#fff'
    },
    Rule: {
      background: '#3B69AD',
      color: '#fff'
    },
    Workflow: {
      background: '#800080',
      color: '#fff'
    },
    Error: {
      background: '#f33',
      color: '#fff'
    },
    default: {
      background: 'transparent',
      color: '#000'
    }
  };

  return (header, message, styleKey = 'default') => {
    console.log(
      `%c ${header} %c\n`,
      `background: ${style[styleKey].background}; color: ${style[styleKey].color};`,
      'background: transparent; color: #000;',
      message
    );
  };
})();

export { toast, log };
