export const mouseoutNode = (cy: any) => {
  return cy.on('mouseout', 'node', async function (e) {
    const node = e.target;

    document.body.style.cursor = 'default';

    node
      .union(node.incomers)
      .union(node.outgoers)
      .removeClass('hover');
  });
};

export const mouseoutEdge = (cy: any) => {
  return cy.on('mouseout', 'edge', async function (e) {
    const edge = e.target;

    document.body.style.cursor = 'default';

    edge
      .union(edge.incomers)
      .union(edge.outgoers)
      .removeClass('hover');
  });
};
