import cytoscape from 'cytoscape';
import $ from 'jquery';
import contextMenus from 'cytoscape-context-menus';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import store from '../../store/store';
import { callFunctions } from '../../api/functions/call.functions';

cytoscape.use(contextMenus, $);

export const contextMenu = (cy: any, contextData: any, addNodesCytoscape: any, removeNode: any, translate: any) => {

  const menuItems = contextData.elements.map((element: any) => (
    {
      id: element.key,
      content: translate(element.label),
      selector: 'node',
      show: true,
      onClickFunction: async (item) => {
        const eventKey = element.events?.find(event => event.type === 'onClick')?.key;

        if (!item) return null;

        runActions(eventKey, parseInt(item.target.data().id), item.target.data(), null)
          .then(() => {
            const data = store.getState().workspaces.instances.find(instance => instance.isActive).data;
            callFunctions(data.functions, { addNodesCytoscape: addNodesCytoscape, removeNode: removeNode, itemPosition: item.position });
          })
          .catch((error) => {
            console.log('Graph contextmenu error:  ', error);
          });
      }
    }
  ));
  return cy.contextMenus({ menuItems: menuItems });
};