import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const LogoContainer = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImage = styled.img`
  max-width: 280px;
`;

const Logo: React.FC<any> = () => {
  const themes = useSelector((state: any) => state.getThemes);

  if (!themes.success) {
    return (
      <LogoContainer />
    );
  }

  return (
    <LogoContainer>
      <LogoImage src={themes?.data?.logo} alt="Logo" />
    </LogoContainer>
  );
};

export default Logo;